var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "homePage", staticClass: "homePage" },
    [
      _c("TypeSelection", {
        staticClass: "typeSelection",
        style: {
          height: _vm.isScrollUp ? "50px" : "0px",
          opacity: _vm.isScrollUp ? "1" : "0",
          overflow: _vm.isScrollUp ? "visible" : "hidden"
        },
        attrs: { isScrollUp: _vm.isScrollUp }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.homeDataLoading,
              expression: "homeDataLoading"
            }
          ],
          ref: "homeContainer",
          staticClass: "homeContainer",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,255,255,0)"
          },
          on: { scroll: _vm.handleHomeContainerScroll }
        },
        [
          _c(
            "div",
            {
              ref: "leftSidebarContainer",
              staticClass: "leftSidebarContainer commonSidebarContainer",
              style: {
                left: `${_vm.leftSidebarOffsetLeft}px`,
                ..._vm.leftSidebarStyle
              }
            },
            [_c("Advertisement")],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadData,
                  expression: "loadData"
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.homeListData.length !== 0,
                  expression: "homeListData.length !== 0"
                }
              ],
              ref: "innerContainer",
              staticClass: "innerContainer",
              attrs: {
                "infinite-scroll-disabled": "loadDisabled",
                "infinite-scroll-distance": "10",
                "infinite-scroll-immediate-check": "false"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "resize-ob",
                      rawName: "v-resize-ob",
                      value: _vm.handlerResize,
                      expression: "handlerResize"
                    }
                  ],
                  ref: "displayContainer",
                  staticClass: "displayContainer"
                },
                _vm._l(_vm.homeListData, function(itemInfo, index) {
                  return _c("HomeScrollItem", {
                    key: itemInfo.gigId + index,
                    staticClass: "postContainer",
                    attrs: { itemInfo: itemInfo },
                    on: { resetLayout: _vm.resetLayout }
                  })
                }),
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showBackToTopButton
                  ? _c(
                      "div",
                      {
                        staticClass: "backToTopButton",
                        style: {
                          bottom: `${_vm.backToTopButtonBottom}px`,
                          left: `${_vm.backToTopButtonLeft}px`
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.backToTop.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("backToTop"),
                              placement: "bottom"
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/newHome/backToTopButton.png")
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("PlaceholderMap", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.homeListData.length === 0,
                expression: "homeListData.length === 0"
              }
            ],
            staticClass: "innerContainer",
            style: { opacity: _vm.hasLoadData ? 1 : 0 },
            attrs: { placeholderType: 0 }
          }),
          _c("SidebarContainer", {
            ref: "rightSidebarContainer",
            staticClass: "commonSidebarContainer",
            style: {
              left: `${_vm.rightSidebarOffsetLeft}px`,
              ..._vm.rightSidebarStyle
            },
            attrs: { needRefresh: _vm.needRefreshUserInfo }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
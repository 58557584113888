import http from "@/utils/http";


// 获取推荐帖子类型列表，默认展示出来的那个
export function getRecommendCategories() {
  return http({
    baseURL: "/",
    url: `rest/system/recommendCategorise/get`,
    method: "get",
    params: { language: localStorage.getItem("langCode") || 1 },
    withCredentials: false
  });
}
// 获取首页帖子数据
export function getHomePageData(params, page) {
  return http({
    baseURL: "/",
    url: `rest/v5/home/helogig/${page}`,
    method: "get",
    params: params,
    withCredentials: false
  });
}

// 获取首页的项目集成(广告)
export function getIntegratedList(showAll = false) {
  return http({
    baseURL: "/",
    url: `/rest/v5/home/integratedList`,
    method: "get",
    params: { language: localStorage.getItem("langCode") || 1,showAll:showAll },
    withCredentials: false
  });
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.adverData, function(item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "advertisement",
          style: {
            padding: item.projectLink != "innerLink://mail" ? `0 20px` : `0px`
          },
          on: {
            click: function($event) {
              return _vm.go(item)
            }
          }
        },
        [
          item.projectLink != "innerLink://mail"
            ? [
                _c("div", { staticClass: "logo-wrap" }, [
                  _c("img", { attrs: { src: item.iconLink } })
                ]),
                _vm._v("\n      " + _vm._s(item.name) + "\n    ")
              ]
            : [
                _c("div", { staticClass: "emailBlock" }, [
                  _c("img", {
                    staticClass: "emailInlet",
                    attrs: {
                      src: require("../../../assets/images/email/main_register.png")
                    }
                  }),
                  _c("div", { staticClass: "advertisementText" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("advertisementText")) +
                        "\n        "
                    )
                  ])
                ])
              ]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
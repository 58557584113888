<template>
  <div class="scrollItemContent">
    <PostInformationItem
      v-if="!itemInfo.recommendProviders && !itemInfo.recommendGroups"
      :postInfo="itemInfo"
      :operatingMenu="operatingMenu"
      @resetLayout="resetLayout"
    ></PostInformationItem>
    <div
      v-if="
        (itemInfo.recommendProviders &&
          itemInfo.recommendProviders.length !== 0) ||
          (itemInfo.recommendGroups && itemInfo.recommendGroups.length !== 0)
      "
      class="recommendBox"
    >
      <div class="maskContainer" ref="maskContainer">
        <!-- 推荐用户模块 -->
        <div
          class="recommendContainer"
          v-if="
            itemInfo.recommendProviders &&
              itemInfo.recommendProviders.length !== 0
          "
          :style="{
            width: `${recommendContainerWidth}px`,
            transform: `translate3d(-${recommendDisplacement}px,0,0)`,
          }"
        >
          <div
            class="containerInner"
            v-for="item in itemInfo.recommendProviders"
            :key="item.providerId"
          >
            <PersonalInformationItem
              :providerInfo="item"
            ></PersonalInformationItem>
          </div>
          <div
            class="containerInner jumpButton"
            @click="jumpToPersonRecommendation"
          >
            <span class="iconfont_Me icon-next iconStyle"></span>
          </div>
        </div>
        <!-- 推荐群组模块 -->
        <div
          class="recommendContainer"
          v-if="
            itemInfo.recommendGroups && itemInfo.recommendGroups.length !== 0
          "
          :style="{
            width: `${recommendContainerWidth}px`,
            transform: `translate3d(-${recommendDisplacement}px,0,0)`,
          }"
        >
          <div
            class="containerInner"
            v-for="item in itemInfo.recommendGroups"
            :key="item.groupId"
          >
            <GroupInformationItem :groupInfo="item"></GroupInformationItem>
          </div>
          <div
            class="containerInner jumpButton"
            @click="jumpToGroupRecommendation"
          >
            <span class="iconfont_Me icon-next iconStyle"></span>
          </div>
        </div>
        <span
          class="iconfont_Me icon-left buttonStyle previousButton"
          v-if="isShowPreviousButton"
          @click="previousRecommend"
        ></span>
        <span
          class="iconfont_Me icon-right buttonStyle nextButton"
          v-if="isShowNextButton"
          @click="nextRecommend"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import PostInformationItem from "../../components/homeDisplayComponents/postInformationItem";
import PersonalInformationItem from "../../components/homeDisplayComponents/personalInformationItem";
import GroupInformationItem from "../../components/homeDisplayComponents/groupInformationItem";
import {
  getFavoritesCount,
  startCollection,
  startUnfavorite,
} from "@/api/newVersion/interfaceForFavorites";

import { deletePost, shieldPost } from "@/api/newVersion/personalCenter";
export default {
  components: {
    PersonalInformationItem,
    GroupInformationItem,
    PostInformationItem,
  },
  data() {
    return {
      // 这个标记主要用于左右滑动推荐个人和推荐群组
      recommendIndex: 0,
      maskContainerWidth: 714,
      centerDialogVisible: false,
      textarea: "",
      options: [],
      reasonList: [],
      value: "",
      userInfo: null,
      providerId: null,
    };
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
  },
  computed: {
    // 气泡弹窗操作菜单
    operatingMenu() {
      let operatingMenu = [
        {
          imageSrc: this.itemInfo.bookMarkStatus
            ? require("../../../assets/images/favorites/favorited.png")
            : require("../../../assets/images/favorites/notFavorited.png"),
          imageWidth: 14,
          imageHeight: 13,
          operatingFn: this.collectionAction,
          tooltipContent: this.$t("collection"),
        },
      ];
      // 是管理员或者是自己的帖子,显示删除图标
      if (
        this.$store.getters.userInfo.manager_role == 1 ||
        this.isSelfPublish()
      ) {
        operatingMenu.push({
          iconClassName: "icon-delet",
          operatingFn: this.deleteConfirm,
          tooltipContent: this.$t("delete"),
        });
      }
      // 如果是管理员，有升降序权限
      if (this.$store.getters.userInfo.manager_role == 1) {
        operatingMenu.push(
          {
            iconClassName: "el-icon-bottom",
            operatingFn: this.descendingOrder,
            tooltipContent: this.$t("descending"),
          },
          {
            iconClassName: "el-icon-top",
            operatingFn: this.ascendingOrder,
            tooltipContent: this.$t("ascending"),
          }
        );
      }
      // 不是管理员，也不是自己的帖子，显示投诉功能,显示屏蔽功能
      if (
        this.$store.getters.userInfo.manager_role != 1 &&
        !this.isSelfPublish()
      ) {
        operatingMenu.push(
          {
            iconClassName: "iconfont_Me icon-a-zu5510",
            operatingFn: this.complaintConfirm,
            tooltipContent: this.$t("complaint"),
          },
          {
            iconClassName: "iconfont_Me icon-a-zu9817",
            operatingFn: this.shieldConfirm,
            tooltipContent: this.$t("shield"),
          }
        );
      }
      return operatingMenu;
    },
    // 是否可以显示操作滑动的按钮
    isShowNextButton() {
      let flag = false;
      // 当容器宽度减去位移距离，还大于装载容器宽度时则显示按钮
      if (
        this.recommendContainerWidth - this.recommendDisplacement >
        this.maskContainerWidth
      ) {
        flag = true;
      }
      return flag;
    },
    // 是否可以显示操作滑动的按钮
    isShowPreviousButton() {
      let flag = false;
      if (this.recommendDisplacement > 0) {
        flag = true;
      }
      return flag;
    },
    // 获取装载容器位移值
    recommendDisplacement() {
      let displacement = 0;
      displacement = displacement + this.recommendIndex * 290;
      if (
        this.$refs.maskContainer &&
        displacement + this.$refs.maskContainer.offsetWidth >
          this.recommendContainerWidth
      ) {
        this.maskContainerWidth = this.$refs.maskContainer.offsetWidth;
        displacement = this.recommendContainerWidth - this.maskContainerWidth;
      }
      return displacement;
    },
    // 获取装载容器的宽度
    recommendContainerWidth() {
      let recommendLength = 0;
      if (this.itemInfo.recommendProviders) {
        recommendLength = this.itemInfo.recommendProviders.length + 1;
      } else if (this.itemInfo.recommendGroups) {
        recommendLength = this.itemInfo.recommendGroups.length + 1;
      }
      let widthCount = recommendLength * 280 + (recommendLength - 1) * 10;
      return widthCount;
    },
  },
  props: {
    // 滚动组件
    itemInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // 监听列表数据中的收藏状态变化(主要是为了监听在收藏弹窗中操作了数据过后改变帖子列表收藏状态)
    "$store.state.favorites.hasBeenOperatedObject": {
      handler(val, old) {
        if (val && val !== old) {
          if (
            val.gigId === this.itemInfo.gigId &&
            this.$route.name === "home"
          ) {
            this.itemInfo.bookMarkStatus = !this.itemInfo.bookMarkStatus;
            this.$store.commit("setHasBeenOperatedObject", null);
            this.$forceUpdate();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 获取收藏夹总数
    getFavoritesCountData() {
      return new Promise(async (resolve, reject) => {
        let result = await getFavoritesCount();
        if (result.code == 200) {
          resolve(result.data.data);
        } else {
          reject(result.message);
        }
      });
    },
    // 收藏操作
    async collectionAction(postInfo) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      // 如果已经是收藏状态的话，则执行取消收藏操作
      if (this.itemInfo.bookMarkStatus) {
        await this.submitUnfavoriteInfo(postInfo);
      } else {
        // 这里需要获取一下是否有收藏夹，如果没有的话就直接收藏，如果有的话则先打开收藏弹窗
        this.getFavoritesCountData()
          .then(async (result) => {
            let handlerPostInfo = _.cloneDeep(postInfo);
            handlerPostInfo.favoritesType = "post";
            if (result > 0) {
              // 将当前帖子基本信息存入收藏状态管理器
              this.$store.commit(
                "setNeedToOperatObjectInformation",
                handlerPostInfo
              );
              // 这一步是展示选择收藏夹组件
              this.$store.commit("setNeedToShowInDialog", "selectFavoritesBox");
              // 打开收藏操作弹窗
              this.$store.commit("setFavoritesDialogStatus", true);
            } else {
              // 如果没有收藏夹，则直接收藏
              await this.favoriteCurrentPost(handlerPostInfo);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error,
            });
          });
      }
    },
    // 取消收藏
    async submitUnfavoriteInfo(itemInfo) {
      let result = await startUnfavorite(itemInfo.gigId);
      if (result.code == 200) {
        // 这里存储用于刷新首页列表帖子收藏状态
        this.$store.commit("setHasBeenOperatedObject", itemInfo);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 收藏当前帖子
    favoriteCurrentPost(postInfo) {
      return new Promise(async (resolve, reject) => {
        let dataId = postInfo.gigId;
        let params = { type: "1" };
        let result = await startCollection(dataId, params);
        if (result.code == 200) {
          // 这里存储用于刷新首页列表帖子收藏状态
          this.$store.commit("setHasBeenOperatedObject", postInfo);
          // ===================================================================================================
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 跳转到人员推荐列表
    jumpToPersonRecommendation() {
      // 每次进入搜索页面都将感兴趣列表设置为默认人员列表
      this.$store.commit("setSearchTabIndex", 0);
      this.routeJump({
        name: "newSearch",
        params: { isInterestedSearch: "1" },
      });
    },
    // 跳转到群组推荐列表
    jumpToGroupRecommendation() {
      // 每次进入搜索页面都将感兴趣列表设置为默认群组列表
      this.$store.commit("setSearchTabIndex", 1);
      this.routeJump({
        name: "newSearch",
        params: { isInterestedSearch: "1" },
      });
    },
    // 刷新瀑布流布局
    resetLayout() {
      this.$emit("resetLayout");
    },
    // 下一个推荐
    nextRecommend() {
      this.recommendIndex++;
    },
    // 上一个推荐
    previousRecommend() {
      this.recommendIndex--;
    },
    deleteConfirm() {
      // 自己删除自己的帖子
      if (this.isSelfPublish()) {
        this.confirm(null, null, "el-icon-delete", this.deletePost);
      } else {
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", this.itemInfo.gigId);
        this.$store.commit("setDeleteDialogVisible", true);
      }
    },
    // 投诉处理
    complaintConfirm() {
      this.$store.commit("setComplaintType", 1);
      this.$store.commit("setComplaintId", this.itemInfo.gigId);
      this.$store.commit("setComplaintDialogVisible", true);
    },
    // 屏蔽帖子处理
    async shieldConfirm() {
      this.confirm(null, this.$t("blockThisPost"), null, this.shieldPost);
    },
    //屏蔽帖子
    async shieldPost() {
      let result = await shieldPost(this.itemInfo.gigId);
      if (result.code == 200) {
        // 暂时按删除帖子的逻辑处理
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", this.itemInfo.gigId);
        this.$store.commit("setDeleteStatus", true);
        this.$message({
          message: "success",
          type: "success",
        });
      }
    },
    // 删除自己的帖子
    async deletePost() {
      let params = {
        helogigId: this.itemInfo.gigId,
        userId: this.$store.getters.userInfo.id,
      };
      let result = await deletePost(params);
      if (result) {
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", this.itemInfo.gigId);
        this.$store.commit("setDeleteStatus", true);
      }
    },
    // 降序
    descendingOrder() {
      this.$store.commit("setDescendingOrderPostId", this.itemInfo.gigId);
      this.$store.commit("setDescendingOrderPopoverVisible", true);
      this.routeJump({ name: "home" });
    },
    // 升序
    ascendingOrder() {
      this.$store.commit("setAscendingOrderPostId", this.itemInfo.gigId);
      this.$store.commit("setAscendingOrderPopoverVisible", true);
      this.routeJump({ name: "home" });
    },
    // 判断是否是自己发布的帖子
    isSelfPublish() {
      return this.$store.getters.userInfo.id == this.itemInfo.providerId;
    },
  },
};
</script>
<style lang="stylus" scoped>
.scrollItemContent {
  width: 100%;
  min-height: 0px;
  box-sizing: border-box;
  overflow: hidden;
  background: #FFFFFF;

  .recommendBox {
    width: 100%;
    background: #FFFFFF;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    position: relative;

    .maskContainer {
      position: relative;
      overflow: hidden;

      .recommendContainer {
        min-height: 0;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        padding: 20px 0;
        transition: transform 0.3s;

        .containerInner {
          width: 280px;
          min-height: 290px;
          display: inline-block;
          box-sizing: border-box;
          border: 1px solid #e6e6e6;
          border-radius: 9px;
          margin-right: 10px;
          overflow: hidden;
          box-shadow: 0px 3px 6px 0px rgba(204, 204, 204, 0.16);

          &:last-child {
            margin-right: 0;
          }
        }

        .jumpButton {
          height: calc(100% - 40px);
          cursor: pointer;
          background: #F2FCF8;
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);

          .iconStyle {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            position: absolute;
            font-size: 30px;
            color: #666666;
          }
        }
      }

      .previousButton {
        left: 20px;
      }

      .nextButton {
        right: 20px;
      }

      .buttonStyle {
        top: 50%;
        width: 35px;
        height: 35px;
        z-index: 2;
        cursor: pointer;
        position: absolute;
        border-radius: 50%;
        transform: translate3d(0, -50%, 0);
        transition: background 0.3s;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FFFFFF;

        &:hover {
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}

.dele-select {
  .el-select {
    width: 100% !important;
  }

  margin-bottom: 15px;
}
</style>

<style>
.el-message-box__title {
  font-size: 16px;
}

@media screen and (min-width: 1200px) {
  .melinked-el-dialog {
    width: 30%;
  }
}
</style>

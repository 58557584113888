<template>
  <div>
    <div
      @click="go(item)"
      class="advertisement"
      v-for="item in adverData"
      :key="item.id"
      :style="{
        padding: item.projectLink != 'innerLink://mail' ? `0 20px` : `0px`,
      }"
    >
      <template v-if="item.projectLink != 'innerLink://mail'">
        <div class="logo-wrap">
          <img :src="item.iconLink" />
        </div>
        {{ item.name }}
      </template>
      <template v-else>
        <div class="emailBlock">
          <img
            class="emailInlet"
            :src="require('../../../assets/images/email/main_register.png')"
          />
          <div class="advertisementText">
            {{ $t("advertisementText") }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getIntegratedList } from "@/api/newVersion/homePage";
export default {
  data() {
    return {
      videosrc: "",
      adverData: [],
    };
  },
  async mounted() {
    await this.advertisement();
  },
  computed: {
    robotInfo() {
      return this.$store.state.robot.robotInfo;
    },
    lang() {
      return localStorage.getItem("langCode") || 1;
    },
  },
  methods: {
    async go(item) {
      switch (item.projectLink) {
        case "innerLink://mechat":
          await this.meChatInit();
          break;
        case "innerLink://mail":
          if (
            this.$store.getters.userInfo &&
            this.$store.getters.userInfo.identity == 2
          ) {
            this.$store.commit("setTouristTipsFrom", "email");
            this.$store.commit("setTouristTips", true);
          } else {
            const language = localStorage.getItem("langCode") || 1;
            if (language == 1) {
              this.$store.commit("setEmail_loginAndRegisterDialog", true);
            } else {
              this.routeJump({ name: "emailLoginOrRegistration" });
            }
          }
          break;
        default:
          window.open(item.projectLink);
          break;
      }
    },
    async advertisement() {
      const result = await getIntegratedList(true);
      if (result.code == 200 && result.data.data) {
        this.adverData = result.data.data;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.advertisement {
  min-height: 80px;
  background: #ffffff;
  box-sizing: border-box;
  margin-bottom: 0.8333vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  .logo-wrap{
    width 40px
    height 40px
    margin-right: 8px;
    overflow hidden;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .emailBlock {
    width: 100%;
    overflow: hidden;
    position: relative;
    .emailInlet {
      width: 100%;
      overflow: hidden;
      user-select: none;
      display: block;
    }
    .advertisementText {
      position: absolute;
      z-index: 10;
      top: 90px;
      left: 18px;
      color: #D64569;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      font-family: DIN-BlackItalicAlt;
      max-width: 120px
    }
  }
}
</style>

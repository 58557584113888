var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "typeSelectionContainer" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.unfoldFlag
          ? _c(
              "div",
              { staticClass: "defaultDisplayArea" },
              [
                _c(
                  "div",
                  {
                    staticClass: "displayBox",
                    on: {
                      click: function($event) {
                        _vm.unfoldFlag = !_vm.unfoldFlag
                      }
                    }
                  },
                  _vm._l(_vm.defaultDisplayData, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "typeItemBox",
                        class:
                          _vm.selectedCategorys.length != 0
                            ? "selectedStyle"
                            : ""
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                ),
                _vm.selectedCategorys.length == 0
                  ? [
                      _c("div", { staticClass: "isolationLine" }),
                      _c(
                        "div",
                        {
                          staticClass: "operationButton",
                          on: {
                            click: function($event) {
                              _vm.unfoldFlag = !_vm.unfoldFlag
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-class iconStyle"
                          })
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm.selectedCategorys.length != 0
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "clearButton",
                          on: { click: _vm.clearSelected }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-qingchu iconStyle"
                          })
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.unfoldFlag
          ? _c("div", { staticClass: "unfoldDisplayArea" }, [
              _c(
                "div",
                { staticClass: "displayBox" },
                _vm._l(_vm.categorys, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "typeItemBox",
                      class: item.isSelected ? "selectedStyle" : "",
                      on: {
                        click: function($event) {
                          return _vm.selectedType(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "footerBox" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: { content: _vm.$t("clear"), placement: "bottom" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "buttonStyle leftButton",
                          on: { click: _vm.clearSelection }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-qingchu iconStyle"
                          })
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "buttonStyle rightButton",
                      on: { click: _vm.confirmSelection }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-check iconStyle"
                      })
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scrollItemContent" },
    [
      !_vm.itemInfo.recommendProviders && !_vm.itemInfo.recommendGroups
        ? _c("PostInformationItem", {
            attrs: { postInfo: _vm.itemInfo, operatingMenu: _vm.operatingMenu },
            on: { resetLayout: _vm.resetLayout }
          })
        : _vm._e(),
      (_vm.itemInfo.recommendProviders &&
        _vm.itemInfo.recommendProviders.length !== 0) ||
      (_vm.itemInfo.recommendGroups &&
        _vm.itemInfo.recommendGroups.length !== 0)
        ? _c("div", { staticClass: "recommendBox" }, [
            _c("div", { ref: "maskContainer", staticClass: "maskContainer" }, [
              _vm.itemInfo.recommendProviders &&
              _vm.itemInfo.recommendProviders.length !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "recommendContainer",
                      style: {
                        width: `${_vm.recommendContainerWidth}px`,
                        transform: `translate3d(-${_vm.recommendDisplacement}px,0,0)`
                      }
                    },
                    [
                      _vm._l(_vm.itemInfo.recommendProviders, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.providerId,
                            staticClass: "containerInner"
                          },
                          [
                            _c("PersonalInformationItem", {
                              attrs: { providerInfo: item }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "containerInner jumpButton",
                          on: { click: _vm.jumpToPersonRecommendation }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-next iconStyle"
                          })
                        ]
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm.itemInfo.recommendGroups &&
              _vm.itemInfo.recommendGroups.length !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "recommendContainer",
                      style: {
                        width: `${_vm.recommendContainerWidth}px`,
                        transform: `translate3d(-${_vm.recommendDisplacement}px,0,0)`
                      }
                    },
                    [
                      _vm._l(_vm.itemInfo.recommendGroups, function(item) {
                        return _c(
                          "div",
                          { key: item.groupId, staticClass: "containerInner" },
                          [
                            _c("GroupInformationItem", {
                              attrs: { groupInfo: item }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "containerInner jumpButton",
                          on: { click: _vm.jumpToGroupRecommendation }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-next iconStyle"
                          })
                        ]
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isShowPreviousButton
                ? _c("span", {
                    staticClass:
                      "iconfont_Me icon-left buttonStyle previousButton",
                    on: { click: _vm.previousRecommend }
                  })
                : _vm._e(),
              _vm.isShowNextButton
                ? _c("span", {
                    staticClass:
                      "iconfont_Me icon-right buttonStyle nextButton",
                    on: { click: _vm.nextRecommend }
                  })
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
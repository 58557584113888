<template>
  <div class="homePage" ref="homePage">
    <TypeSelection
      class="typeSelection"
      :style="{
        height: isScrollUp ? '50px' : '0px',
        opacity: isScrollUp ? '1' : '0',
        overflow: isScrollUp ? 'visible' : 'hidden',
      }"
      :isScrollUp="isScrollUp"
    ></TypeSelection>
    <div
      class="homeContainer"
      ref="homeContainer"
      v-loading="homeDataLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,0)"
      @scroll="handleHomeContainerScroll"
    >
      <div
        class="leftSidebarContainer commonSidebarContainer"
        ref="leftSidebarContainer"
        :style="{ left: `${leftSidebarOffsetLeft}px`, ...leftSidebarStyle }"
      >
        <Advertisement />
      </div>
      <div
        class="innerContainer"
        v-infinite-scroll="loadData"
        infinite-scroll-disabled="loadDisabled"
        infinite-scroll-distance="10"
        infinite-scroll-immediate-check="false"
        ref="innerContainer"
        v-show="homeListData.length !== 0"
      >
        <div
          class="displayContainer"
          ref="displayContainer"
          v-resize-ob="handlerResize"
        >
          <HomeScrollItem
            v-for="(itemInfo, index) in homeListData"
            :key="itemInfo.gigId + index"
            :itemInfo="itemInfo"
            class="postContainer"
            @resetLayout="resetLayout"
          ></HomeScrollItem>
        </div>
        <transition name="fade">
          <div
            class="backToTopButton"
            @click.stop="backToTop"
            v-if="showBackToTopButton"
            :style="{
              bottom: `${backToTopButtonBottom}px`,
              left: `${backToTopButtonLeft}px`,
            }"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('backToTop')"
              placement="bottom"
            >
              <img
                :src="
                  require('../../../assets/images/newHome/backToTopButton.png')
                "
              />
            </el-tooltip>
          </div>
        </transition>
      </div>
      <PlaceholderMap
        :placeholderType="0"
        class="innerContainer"
        :style="{ opacity: hasLoadData ? 1 : 0 }"
        v-show="homeListData.length === 0"
      ></PlaceholderMap>
      <SidebarContainer
        ref="rightSidebarContainer"
        :needRefresh="needRefreshUserInfo"
        class="commonSidebarContainer"
        :style="{ left: `${rightSidebarOffsetLeft}px`, ...rightSidebarStyle }"
      ></SidebarContainer>
    </div>
  </div>
</template>
<script>
import Masonry from "masonry-layout";
import TypeSelection from "./typeSelection";
import Advertisement from "../../components/advertisement";
import SidebarContainer from "./sidebarContainer";
import { getHomePageData } from "@/api/newVersion/homePage";
import PlaceholderMap from "../../components/placeholderMap";
import HomeScrollItem from "../../components/homeDisplayComponents/homeScrollItem";
export default {
  components: {
    Advertisement,
    TypeSelection,
    SidebarContainer,
    HomeScrollItem,
    PlaceholderMap,
  },
  data() {
    return {
      // 下拉加载禁止
      loadDisabled: false,
      // 首页数据加载状态
      homeDataLoading: false,
      // 组装完成的帖子信息数据
      homeListData: [],
      // 每一页展示的数量
      pageSize: 20,
      // 当前页数
      currentPage: 1,
      // 瀑布流对象
      msnry: null,
      // 滚动方向监听
      scrollTop: 0,
      // 在向上滚动
      isScrollUp: true,
      // 最后一个帖子id
      lastRecommendGigId: null,
      // 最后一个帖子id
      lastRecommendGigId: null,
      // 最后一个推荐群组id
      lastRecommendGroupId: null,
      // 最后一个推荐个人id
      lastRecommendProviderId: null,
      // 需要刷新用户信息的标记
      needRefreshUserInfo: 0,
      // 用于判断首页数据加载接口已触发过，只有触发过，才能显示占位图
      hasLoadData: false,
      // 返回页面顶部按钮的坐标
      backToTopButtonBottom: 0,
      backToTopButtonLeft: 0,
      // 是否展示返回顶部按钮
      showBackToTopButton: false,
      // 返回顶部帧函数对象
      scrollToFrameAnimation: null,
      //主页滚动帧回调
      homeScrollAnimationFrame: null,
      // 左侧边栏浮动属性
      leftSidebarOffsetLeft: 0,
      rightSidebarOffsetLeft: 0,
      leftSidebarStyle: {
        top: "0",
        position: "static",
      },
      // 右侧边栏浮动属性
      rightSidebarStyle: {
        top: "0",
        position: "static",
      },
    };
  },
  watch: {
    // 监听地区变化
    "$store.state.homePage.searchCountries": {
      async handler(val, old) {
        if (val !== old) {
          this.lastRecommendGigId = null;
          this.lastRecommendGroupId = null;
          this.lastRecommendProviderId = null;
          await this.$nextTick();
          await this.startGetSearchData();
        }
      },
      deep: true,
    },
    // 监听类型变化
    "$store.state.homePage.searchCategories": {
      async handler(val, old) {
        if (val !== old) {
          this.lastRecommendGigId = null;
          this.lastRecommendGroupId = null;
          this.lastRecommendProviderId = null;
          await this.$nextTick();
          await this.startGetSearchData();
        }
      },
      deep: true,
    },
    // 监听是否成功删除帖子的状态
    "$store.state.deleteReason.deleteStatus": {
      async handler(val, old) {
        if (val && val !== old) {
          let deletePostId = this.$store.state.deleteReason.deletePostId;
          await this.deleteOne(deletePostId);
          await this.$forceUpdate();
          this.$store.commit("setDeletePostId", null);
          this.$store.commit("setDeleteStatus", false);
        }
      },
      deep: true,
    },
    // 监听是否降序成功的状态
    "$store.state.sort.descendingOrderStatus": {
      async handler(val, old) {
        if (val && val !== old) {
          await this.refreshData();
          await this.$forceUpdate();
        }
      },
    },
    // 监听是否升序成功的状态
    "$store.state.sort.ascendingOrderStatus": {
      async handler(val, old) {
        if (val && val !== old) {
          await this.refreshData();
          await this.$forceUpdate();
        }
      },
    },
    // 监听多媒体弹窗的状态
    multimediaPopupStatus: {
      handler(val, old) {
        if (val !== old && !val) {
          let records = [...this.$store.state.homePage.alreadyLikedRecords];
          this.handleLikedRecords(records);
          let comments = [...this.$store.state.homePage.listOfLatestComments];
          this.handleLatestComments(comments);
        }
      },
      deep: true,
    },
    // 监听首页数据变化
    "homeListData.length": {
      async handler(val, old) {
        if (val !== old) {
          await this.$nextTick();
          this.resizeInit();
        }
      },
      deep: true,
    },
  },
  computed: {
    // 多媒体弹窗状态
    multimediaPopupStatus() {
      return (
        this.$store.state.homePage.multimediaPopupConfig.videoPath !== "" ||
        this.$store.state.homePage.multimediaPopupConfig.pictureCollection
          .length !== 0
      );
    },
  },
  async mounted() {
    await this.startGetSearchData();
    if (!this.msnry) {
      this.msnryInit();
    }
  },
  beforeDestroy() {
    this.msnryDestroy();
    window.cancelAnimationFrame(this.homeScrollAnimationFrame);
    window.cancelAnimationFrame(this.scrollToFrameAnimation);
  },
  // 激活keep-alive的时候，执行activated钩子回调
  async activated() {
    try {
      // 监听键盘按钮
      document.addEventListener("keyup", this.keyupChange);
      window.addEventListener("resize", this.resizeInit, true);
      this.$refs.homeContainer.scrollTo(0, this.scrollTop);
      // 每次触发，都要更新一下名片的最新信息
      this.needRefreshUserInfo++;
      // 每次进来都检查一下是否有需要插入列表第一个的新发布的帖子,如果有新发布的帖子，则将首页滚动到最顶端
      if (
        this.$store.state.publishArticle.dataToBeDisplayedOnTheHomepage.gigId
      ) {
        this.homeListData.unshift(
          this.$store.state.publishArticle.dataToBeDisplayedOnTheHomepage
        );
        this.$store.commit("setDataToBeDisplayedOnTheHomepage", {});
        await this.$nextTick();
        this.$refs.homeContainer.scrollTo(0, 0);
      }
      // 每次进来都判断一下喜欢记录列表中是否有值，有的话，将状态更新到首页列表中
      if (this.$store.state.homePage.alreadyLikedRecords.length !== 0) {
        let records = [...this.$store.state.homePage.alreadyLikedRecords];
        this.handleLikedRecords(records);
      }
      if (this.$store.state.homePage.listOfLatestComments.length !== 0) {
        let comments = [...this.$store.state.homePage.listOfLatestComments];
        this.handleLatestComments(comments);
      }
      await this.$nextTick();
      this.resizeInit();
    } catch (error) {}
  },
  // 离开的时候，执行deactivated钩子，删除监听事件
  deactivated() {
    try {
      document.removeEventListener("keyup", this.keyupChange);
      window.removeEventListener("resize", this.resizeInit, true);
    } catch (error) {}
  },
  methods: {
    handlerResize: _.debounce(function(size) {
      if (this.homeListData.length != 0) {
        this.resetMsnry();
      }
    }, 1000),
    resetMsnry: _.debounce(function() {
      this.msnryDestroy();
      this.msnryInit();
    }, 100),
    msnryInit() {
      let grid = this.$refs.displayContainer;
      this.msnry = new Masonry(grid, {
        itemSelector: ".postContainer",
        columnWidth: 580,
        gutter: 16,
        transitionDuration: "0.1s",
      });
    },
    msnryDestroy() {
      if (this.masonry) {
        this.msnry.destroy();
        this.msnry = null;
      }
    },
    handleHomeContainerScroll: _.throttle(function() {
      if (this.homeScrollAnimationFrame) return;
      this.homeScrollAnimationFrame = window.requestAnimationFrame(() => {
        const scrollTop = this.$refs.homeContainer.scrollTop;
        this.homeContainerScrolling(scrollTop);
        const homeContainerOffsetTop =
          this.$refs.homePage.offsetTop +
          this.$refs.homeContainer.offsetTop +
          10;
        let style = {};
        if (scrollTop > 0) {
          style = {
            position: "fixed",
            top: `${homeContainerOffsetTop}px`,
          };
        } else {
          style = {
            position: "static",
            top: `0`,
          };
        }
        this.setSize();
        this.leftSidebarStyle = style;
        this.rightSidebarStyle = style;
        this.homeScrollAnimationFrame = null;
      });
    }, 160),
    homeContainerScrolling(scrollTop) {
      // 如果滚动高度超过100，就显示返回顶部按钮
      if (scrollTop > 240) {
        this.showBackToTopButton = true;
      } else {
        this.showBackToTopButton = false;
      }
      // 判断滚动方向
      let scroll = scrollTop - this.scrollTop;
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.isScrollUp = true;
      } else {
        this.isScrollUp = false;
      }
    },
    async resizeInit() {
      this.setSize();
      await this.$nextTick();
      this.resetMsnry();
    },
    // 设置返回顶部按钮位置
    setSize() {
      const innerLeft = this.$refs.innerContainer
        ? this.$refs.innerContainer.offsetLeft
        : 0;
      const innerWidth = this.$refs.innerContainer
        ? this.$refs.innerContainer.clientWidth
        : 0;
      const sidebarWidth = this.$refs.rightSidebarContainer.$el.offsetWidth;
      this.leftSidebarOffsetLeft = innerLeft - sidebarWidth - 16;
      this.rightSidebarOffsetLeft = innerLeft + innerWidth;
      this.backToTopButtonLeft = this.rightSidebarOffsetLeft + 16;
      this.backToTopButtonBottom = 70;
    },
    // 键盘向上按钮触发
    keyupChange(e) {
      if (this.$store.state.imStore.IMWindowSize !== "mini") {
        return;
      }
      if (e.keyCode === 38) {
        this.$refs.homeContainer.scrollTo(
          0,
          this.$refs.homeContainer.scrollTop - 200
        );
      } else if (e.keyCode === 40) {
        this.$refs.homeContainer.scrollTo(
          0,
          this.$refs.homeContainer.scrollTop + 200
        );
      }
    },
    // 点击返回顶部
    backToTop() {
      if (this.$refs.homeContainer) {
        this.frameAnimation(this.$refs.homeContainer.scrollTop);
      }
    },
    // 返回顶部的帧动画
    frameAnimation(scrollTop, rate = 1) {
      this.scrollToFrameAnimation = window.requestAnimationFrame(() => {
        scrollTop = scrollTop - 10 * rate;
        this.$refs.homeContainer.scrollTo(0, scrollTop > 0 ? scrollTop : 0);
        if (scrollTop > 0) {
          this.frameAnimation(scrollTop, rate * 2);
        } else {
          window.cancelAnimationFrame(this.scrollToFrameAnimation);
        }
      });
    },
    // 处理评论记录列表中的数据，填充到首页数据中
    handleLatestComments(comments) {
      comments.forEach((element) => {
        this.homeListData = this.homeListData.map((item) => {
          // 判断首页列表中的推荐群列表
          if (item.recommendGroups && item.recommendGroups.length !== 0) {
            item.recommendGroups = item.recommendGroups.map((e) => {
              if (e.groupId === element.commentId) {
                e.commentCount = element.commentCount;
              }
              return e;
            });
            // 判断首页列表中的推荐人员列表
          } else if (
            item.recommendProviders &&
            item.recommendProviders.length !== 0
          ) {
            item.recommendProviders = item.recommendProviders.map((e) => {
              if (e.providerId === element.commentId) {
                e.commentCount = element.commentCount;
              }
              return e;
            });
            // 判断首页列表中的帖子数据
          } else {
            if (item.gigId === element.commentId) {
              item.commentCount = element.commentCount;
            }
          }
          return item;
        });
        this.$forceUpdate();
      });
    },
    // 处理喜欢记录列表中的状态数据，填充到首页数据中
    handleLikedRecords(records) {
      records.forEach((element) => {
        this.homeListData = this.homeListData.map((item) => {
          // 判断首页列表中的推荐群列表
          if (item.recommendGroups && item.recommendGroups.length !== 0) {
            item.recommendGroups = item.recommendGroups.map((e) => {
              if (e.groupId === element.likeItemId) {
                e.likeCount = element.likeCount;
                e.likeStatus = element.likeStatus;
              }
              return e;
            });
            // 判断首页列表中的推荐人员列表
          } else if (
            item.recommendProviders &&
            item.recommendProviders.length !== 0
          ) {
            item.recommendProviders = item.recommendProviders.map((e) => {
              if (e.providerId === element.likeItemId) {
                e.likeCount = element.likeCount;
                e.likeStatus = element.likeStatus;
              }
              return e;
            });
            // 判断首页列表中的帖子数据
          } else {
            if (item.gigId === element.likeItemId) {
              item.likeCount = element.likeCount;
              item.likeStatus = element.likeStatus;
            }
          }
          return item;
        });
        this.$forceUpdate();
      });
    },
    // 主动调用重排列表
    async resetLayout() {
      await this.$nextTick();
      if (this.msnry) {
        this.msnry.layout();
      }
    },
    // 删除了一条
    deleteOne(id) {
      return new Promise((resolve, reject) => {
        this.homeListData = this.homeListData.filter((item) => {
          return item.gigId != id;
        });
        resolve();
      });
    },
    // 首页初始化搜索帖子列表
    async startGetSearchData() {
      this.homeListData = [];
      // 将当前页面重置为1
      this.currentPage = 1;
      let params = {
        categories: this.$store.state.homePage.searchCategories,
        countries: this.$store.state.homePage.searchCountries,
        lastRecommendGigId: this.lastRecommendGigId,
        lastRecommendGroupId: this.lastRecommendGroupId,
        lastRecommendProviderId: this.lastRecommendProviderId,
      };
      this.homeDataLoading = true;
      this.loadDisabled = true;
      await this.sendRequest(params);
      this.loadDisabled = false;
    },
    // 加载更多数据
    async loadData() {
      this.currentPage++;
      let params = {
        categories: this.$store.state.homePage.searchCategories,
        countries: this.$store.state.homePage.searchCountries,
        lastRecommendGigId: this.lastRecommendGigId,
        lastRecommendGroupId: this.lastRecommendGroupId,
        lastRecommendProviderId: this.lastRecommendProviderId,
      };
      this.homeDataLoading = true;
      this.loadDisabled = true;
      await this.sendRequest(params);
      try {
        const newElements = this.$refs.displayContainer.children;
        const filteredElements = [...newElements].filter((element) => {
          return !this.msnry.getItemElements().includes(element);
        });
        this.msnry.appended(filteredElements);
      } catch (error) {
        console.log(error);
      }
      this.loadDisabled = false;
    },
    async refreshData() {
      let params = {
        categories: "",
        countries: "",
        lastRecommendGigId: null,
        lastRecommendGroupId: null,
        lastRecommendProviderId: null,
      };
      let result = await getHomePageData(params, 1);
      if (result.code == 200) {
        try {
          let recommendHelogigs = result.data.data.recommendHelogigs;
          this.homeListData = recommendHelogigs;
        } catch {
          console.log("刷新获取数据失败!");
        }
      }
    },
    // 随机获取需要插入的下标
    random(lower, upper) {
      return Math.floor(Math.random() * (upper - lower + 1)) + lower;
    },
    // 开始加载数据
    sendRequest(params) {
      return new Promise(async (resolve, reject) => {
        this.hasLoadData = false;
        let result = await getHomePageData(params, this.currentPage);
        if (result.code == 200) {
          try {
            // 获取最新一段的帖子列表
            let recommendHelogigs = result.data.data.recommendHelogigs;
            // 组装推荐个人列表
            let recommendProviders = {
              // 这个ID是为了用于循环key使用的，可以不管
              gigId: Date.now().toString(),
              recommendProviders: result.data.data.recommendProviders,
            };
            // 组装推荐群组列表
            let recommendGroups = {
              // 这个ID是为了用于循环key使用的，加一是为了和上面个人的区别一下
              gigId: (Date.now() + 1).toString(),
              recommendGroups: result.data.data.recommendGroups,
            };
            // 将推荐的人列表随机插入帖子列表
            recommendHelogigs.splice(
              this.random(2, recommendHelogigs.length - 1),
              0,
              recommendProviders
            );
            // 将推荐的人列表随机插入帖子列表
            recommendHelogigs.splice(
              this.random(2, recommendHelogigs.length - 1),
              0,
              recommendGroups
            );
            this.homeListData = this.homeListData.concat(recommendHelogigs);
            // 获取并记录最后一个帖子的ID
            this.lastRecommendGigId =
              result.data.data.recommendHelogigs[
                result.data.data.recommendHelogigs.length - 1
              ].gigId;
            // 获取并记录最后一个群组的ID
            this.lastRecommendGroupId =
              result.data.data.recommendGroups[
                result.data.data.recommendGroups.length - 1
              ].groupId;
            // 获取并记录最后一个个人的ID
            this.lastRecommendProviderId =
              result.data.data.recommendProviders[
                result.data.data.recommendProviders.length - 1
              ].providerId;
            this.hasLoadData = true;
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        await this.$nextTick();
        this.homeDataLoading = false;
        resolve("success");
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.homePage {
  width: 100vw;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  .typeSelection {
    flex-shrink: 0;
    transition: opacity 0.3s, height 0.3s;
  }

  .homeContainer {
    flex: 1;
    min-height: 0;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
      background: rgba(100, 100, 100, 0.1);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.05);
    }
    .commonSidebarContainer {
      flex-shrink: 0
    }
    .leftSidebarContainer {
      width: 268px;
      min-height: 100px;
      margin-right: 16px;
      overflow: hidden;
      padding-top: 16px;
    }
    .innerContainer {
      background: transparent;
      overflow: hidden;
      width: 1176px;
      flex-shrink: 0;

      @media screen and (max-width: 1744px) {
        width: 580px;
      }

      .backToTopButton {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.6);
        }

        img {
          width: 13px;
          height: 16px;
        }
      }

      .postContainer {
        width: 580px;
        min-height: 0px;
        margin-bottom: 16px;
        background: #FFFFFF;
      }
    }
  }
}
</style>

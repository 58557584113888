<template>
  <div class="sidebarContainer">
    <div class="goToPublishButtonBox">
      <div class="publishButton" @click="goPublishPage">
        <el-tooltip :content="$t('post')" placement="bottom">
          <div class="publishIcon">
            <span class="iconfont_Me icon-edit1 iconStyle"></span>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div
      class="profileArea"
      v-if="isLoggingIn"
      v-loading="profileLoading"
      style="margin-bottom:31.5px;"
    >
      <div class="profileHeader">
        <SharePopover
          :shareConfig="shareConfig"
          @shareForMelinked="shareForMelinked"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('share')"
            placement="bottom"
          >
            <div class="shareButtonStyle">
              <span class="iconfont_Me icon-share2 iconStyle"></span>
            </div>
          </el-tooltip>
        </SharePopover>
        <div class="avatarStyle loginAvatarStyle">
          <el-upload
            class="avatar-uploader"
            action="/"
            :http-request="handlerUploadFile"
            :show-file-list="false"
          >
            <Avatar
              :path="userInfo.profilePhoto"
              :international="
                userInfo.country !== '' ? Number(userInfo.country) : 0
              "
              :providerId="$store.getters.userInfo.id"
            ></Avatar>
            <div class="editAvatarMark">
              <span class="iconfont_Me icon-shot iconStyle"></span>
            </div>
          </el-upload>
        </div>
      </div>
      <div
        class="profileName profileCommon"
        :class="userInfo.vipStatus ? 'cvipName' : ''"
        :title="userInfo.name"
      >
        {{
          userInfo.name && userInfo.name.length > 10
            ? userInfo.name.slice(0, 10) + ".."
            : userInfo.name
        }}
        <div class="vipIcon" v-if="userInfo.vipStatus">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('paidMember')"
            placement="bottom"
            popper-class="me-tooltip-effect"
          >
            <el-image
              style="width: 20px; height: 17px;"
              :src="require('@/assets/images/pay/masonry.png')"
              @click.stop="handleClickVip(userInfo.id)"
              fit="fill"
            >
            </el-image>
          </el-tooltip>
        </div>
      </div>
      <div class="profileId profileCommon">ID:{{ userInfo.ucId }}</div>
      <div class="statisticalDataBox">
        <div
          class="statisticalDataItem"
          v-for="(item, index) in statisticalData"
          :key="item.className"
          @click.stop="
            () => {
              if (index !== 2) {
                jumpToDetails(item.tag);
              }
            }
          "
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.tooltipContent"
            placement="bottom"
          >
            <span class="iconfont_Me iconStyle" :class="item.className"></span>
          </el-tooltip>
          {{ handleNewVersionCount(item.count) }}
        </div>
      </div>
      <template v-if="userIntro != ''">
        <div class="dashedLine"></div>
        <div class="introductionBox" v-if="userIntro !== ''">
          <div class="basicInformationIcon">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('../../../assets/images/newHome/aboutMe.png')"
              fit="cover"
            ></el-image>
          </div>
          <div class="introductionContent">
            <div class="contentText maximumNumberOfRows2" :title="userIntro">
              {{ userIntro }}
            </div>
            <div class="contentTags" v-if="tags.length !== 0">
              <div class="contentTagItem" v-for="item in tags" :key="item">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="jumpButton"
        v-if="!profileLoading"
        @click.stop="jumpToDetails(0)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('personalCenter')"
          placement="bottom"
        >
          <span class="iconfont_Me icon-next iconStyle"></span>
        </el-tooltip>
      </div>
    </div>
    <div class="profileArea" v-else>
      <div class="profileHeader">
        <div class="avatarStyle" @click="showLoginDialog">
          <el-image
            style="width: 80px; height: 80px"
            :src="require('../../../assets/images/newHome/notLoggedIn.png')"
            fit="fill"
          ></el-image>
        </div>
      </div>
    </div>
    <MelinkedTips></MelinkedTips>
  </div>
</template>
<script>
import Avatar from "../../components/avatarDisplay";
import SharePopover from "../../components/sharePopover";
import { getPersonnelDetails } from "@/api/newVersion/personalCenter";
import { handlerUploadFileToQiniu } from "@/api/qiniu";
import { handlerAvatar } from "@/api/newVersion";
import MelinkedTips from "../../components/melinkedTips";
export default {
  computed: {
    // 验证是否登录
    isLoggingIn() {
      return this.checkLogin();
    },
    // 分享所需要的参数
    shareConfig() {
      return {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 3,
        // 分享的内容Id
        share_id: this.$store.getters.userInfo.id,
      };
    },
    // 用户个人简介
    userIntro() {
      let userIntro = "";
      try {
        if (this.userInfo.intro && this.userInfo.intro !== "") {
          let introData = JSON.parse(this.userInfo.intro.replace(/\n/g, ""));
          introData.forEach((item) => {
            if (
              item.key === "text" &&
              item.content !== "" &&
              item.content.replace(/\n/g, "") !== ""
            ) {
              userIntro += item.content;
            }
          });
        } else if (this.userInfo.aboutMe && this.userInfo.aboutMe !== "") {
          userIntro = this.userInfo.aboutMe;
        }
      } catch (error) {
        console.log(error);
      }
      return userIntro;
    },
    lang() {
      return localStorage.getItem("langCode") || 1;
    },
  },
  data() {
    return {
      isVip: true,
      // 用户信息
      userInfo: {},
      // 统计数据
      statisticalData: [
        {
          className: "icon-Post-fill",
          count: 0,
          tag: "post",
          tooltipContent: this.$t("posts"),
        },
        {
          className: "icon-group-fill",
          count: 0,
          tag: "group",
          tooltipContent: this.$t("groups"),
        },
        {
          className: "icon-fabulous-fill",
          count: 0,
          tag: "",
          tooltipContent: this.$t("thumbsUp"),
        },
      ],
      // 标签数据
      tags: [],
      // 获取用户详细信息loading
      profileLoading: false,
      // 上传图片的回传对象
      uploadPictureItem: null,
    };
  },
  components: {
    Avatar,
    SharePopover,
    MelinkedTips,
  },
  watch: {
    // 今天本地存储的数据是否有变化
    "$store.getters.userInfo": {
      handler(val, old) {
        if (val !== old) {
          this.$nextTick(() => {
            this.getPersonnelDetails();
          });
        }
      },
      deep: true,
    },
    // 监听图片上传进度
    "uploadPictureItem.path": {
      async handler(val, old) {
        if (val !== old && val !== "") {
          let userinfo = JSON.parse(
            JSON.stringify(this.$store.getters.userInfo)
          );
          userinfo.avatar = val;
          this.$store.commit("setUserinfo", userinfo);
          let params = {
            userId: this.$store.getters.userInfo.id,
            profilePic: val,
          };
          let result = await handlerAvatar(params);
          if (result.code === 200) {
            this.getPersonnelDetails();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        }
      },
      deep: true,
    },
    // 监听是否需要刷新用户信息，需要的这个的原因，是因为首页列表做了alive处理，但是名片信息需要每次进入页面都要更新
    needRefresh: {
      handler(val, old) {
        if (val !== old) {
          this.getPersonnelDetails();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    needRefresh: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    // 前往个人详情
    jumpToDetails(tag) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.routeJump({
        name: "personalCenter",
        params: { id: this.$store.getters.userInfo.id, active: tag },
      });
    },
    // 开始分享名片
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.shareTitle,
        // 分享展示图片
        picurl: shareData.shareImageSrc,
        // 分享展示内容
        briefInfo: shareData.summary,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: "people",
        // 分享对象的唯一ID
        uuid: this.$store.getters.userInfo.id,
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    // 前往发布页面
    goPublishPage() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.routeJump({ name: "newPublish" });
    },
    // 打开登录注册弹窗
    showLoginDialog() {
      this.$store.commit("setLoginAndRegisterDialog", true);
    },
    // 上传图片,改变用户头像
    handlerUploadFile(files) {
      handlerUploadFileToQiniu(files).then((item) => {
        this.uploadPictureItem = item;
      });
    },
    // 获取用户详细信息
    async getPersonnelDetails() {
      this.profileLoading = true;
      if (this.isLoggingIn) {
        let result = await getPersonnelDetails(this.$store.getters.userInfo.id);
        if (result.code == 200) {
          this.userInfo = result.data.data;
          this.userInfo.aboutMe = this.userInfo.aboutMe.replace(/<[^>]+>/g, "");
          this.$set(
            this.statisticalData[0],
            "count",
            this.userInfo.serviceCount
          );
          this.$set(this.statisticalData[1], "count", this.userInfo.groupCount);
          this.$set(this.statisticalData[2], "count", this.userInfo.likeCount);
          if (this.userInfo.skills && this.userInfo.skills !== "") {
            this.tags = this.userInfo.skills.split(",");
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          this.profileLoading = false;
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.sidebarContainer
  width 268px;
  height calc(100vh - 210px);;
  margin-left 16px;
  background transparent;
  overflow-x hidden;
  overflow-y auto;
  scrollbar-width: none;
  &::-webkit-scrollbar
    width 0px;
  .goToPublishButtonBox
    width 100%;
    height 60px;
    background #FFFFFF;
    display flex;
    align-items center;
    justify-content center;
    margin-bottom 18px;
    .publishButton
      height 42px;
      width 232px;
      cursor pointer;
      border-radius 25px;
      background #F0F0F0;
      display flex;
      box-sizing border-box;
      align-items center;
      justify-content flex-start;
      transition background .3s;
      border 1px solid transparent;
      .publishIcon
        width 24px;
        height 24px;
        margin-left 20px;
        .iconStyle
          font-size 24px;
          color #333333;
          transition color .3s;
      &:hover
        border 1px solid #33CC66;
        background rgba(242,252,248,0.6);
        .publishIcon
          .iconStyle
            color #33CC66;
  .profileArea
    width 100%;
    margin-top 10px;
    margin-bottom: 16px;
    min-height 163px;
    border-radius 18px 18px 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 0px rgba(204,204,204,0.16);
    .profileHeader
      width 100%;
      height 82px;
      border-radius 18px 18px 0 0;
      background url("../../../assets/images/newHome/profileHeader.png") no-repeat;
      background-size 100% 100%;
      text-align center;
      position relative;
      .avatarStyle
        width 80px;
        height 80px;
        display inline-block;
        margin-top 40px;
        border-radius 50%;
        overflow hidden;
      .loginAvatarStyle
        border 4px solid #FFFFFF;
        box-sizing border-box;
        overflow visible;
        position relative;
        .editAvatarMark
          position absolute;
          top 50%;
          left 50%;
          opacity 0;
          z-index 1;
          width 100%;
          height 100%;
          display flex;
          cursor pointer;
          border-radius 50%;
          align-items center;
          justify-content center;
          background rgba(0,0,0,0.4);
          transform translate3d(-50%,-50%,0);
          transition opacity .3s;
          &:hover
            opacity 1;
          .iconStyle
            font-size 36px;
            color #ffffff;
      .shareButtonStyle
        width 32px;
        height 32px;
        border-radius 50%;
        position absolute;
        top 12px;
        right 12px;
        box-sizing border-box;
        border 1px solid #E6E6E6;
        background rgba(0,70,23,0.16);
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          color #FFFFFF;
          font-size 22px;
    .profileCommon
      width 100%;
      font-size 14px;
      text-align center;
      line-height 20px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    .profileName
      margin-top 50px;
      color #373737;
      font-weight bold;
    .profileId
      margin-top 8px;
      color #8F8F8F;
    .statisticalDataBox
      height 40px;
      margin 15px 0 0 0;
      padding 0 20px;
      background transparent;
      display flex;
      align-items center;
      justify-content space-between;
      .statisticalDataItem
        width 66px;
        height 34px;
        cursor pointer;
        border-radius 17px;
        box-sizing border-box;
        border 1px solid #E6E6E6;
        background #F7F7F7;
        color #8F8F8F;
        font-size 12px;
        user-select none;
        display flex;
        align-items center;
        justify-content center;
        transition background .3s , color .3s;
        .iconStyle
          font-size 22px;
          color #333333;
          transition color .3s;
        &:hover
          border none;
          background #F2FCF8;
          color #33CC66;
          .iconStyle
            color #33CC66;
    .dashedLine
      width: 100%;
      margin auto;
      height: 1px;
      background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
      background-size: 8px 1px;
      background-repeat: repeat-x;
      margin 12px 0;
    .introductionBox
      width 100%;
      padding 0 25px;
      box-sizing border-box;
      display flex;
      align-items flex-start;
      justify-content flex-start;
      .introductionContent
        flex 1;
        min-width 0;
        overflow hidden;
        min-height 24px;
        padding-left 10px;
        box-sizing border-box;
        .contentText
          width 100%;
          font-size 12px;
          line-height 20px;
          color #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
        .maximumNumberOfRows2
          -webkit-line-clamp:2;
        .contentTags
          width 100%;
          text-align left;
          line-height 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          .contentTagItem
            height 20px;
            line-height 20px;
            border-radius 4px;
            padding 0 6px;
            font-size 12px;
            color #333333;
            margin-right 4px;
            display inline-block;
            background rgba(143,143,143,0.1);
    .basicInformationIcon
      width 10px;
      height 12px;
      margin-top 4px;
      flex-shrink 0;
      overflow hidden;
      display flex;
      align-items center;
      justify-content center;
      img
        width 100%;
        height 100%;
  .jumpButton
    width 40px;
    height 40px;
    border-radius 50%;
    background #FFFFFF;
    margin auto;
    cursor pointer;
    display flex;
    align-items center;
    justify-content center;
    position relative;
    top 20px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    .iconStyle
      color #1D4329;
      font-size 18px;
</style>
<style lang="stylus">
.profileArea
  .el-loading-mask
    border-radius 18px 18px 20px 20px;
  .loginAvatarStyle
    .avatar-uploader
      width 100%;
      height 100%;
      .el-upload
        width 100%;
        height 100%;
</style>

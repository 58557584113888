<template>
  <div class="typeSelectionContainer">
    <transition name="fade">
      <div class="defaultDisplayArea" v-if="!unfoldFlag">
        <div class="displayBox" @click="unfoldFlag = !unfoldFlag">
          <div class="typeItemBox" :class="selectedCategorys.length != 0 ? 'selectedStyle' : ''"
            v-for="item in defaultDisplayData" :key="item.id">{{ item.name }}</div>
        </div>
        <template v-if="selectedCategorys.length == 0">
          <div class="isolationLine"></div>
          <div class="operationButton" @click="unfoldFlag = !unfoldFlag">
            <span class="iconfont_Me icon-class iconStyle"></span>
          </div>
        </template>
        <template v-if="selectedCategorys.length != 0">
          <div class="clearButton" @click="clearSelected">
            <span class="iconfont_Me icon-qingchu iconStyle"></span>
          </div>
        </template>
      </div>
    </transition>
    <transition name="fade">
      <div class="unfoldDisplayArea" v-if="unfoldFlag">
        <div class="displayBox">
          <div class="typeItemBox" :class="item.isSelected ? 'selectedStyle' : ''" v-for="(item, index) in categorys"
            :key="item.id" @click="selectedType(index)">{{ item.name }}</div>
        </div>
        <div class="footerBox">
          <el-tooltip :content="$t('clear')" placement="bottom">
            <div class="buttonStyle leftButton" @click="clearSelection">
              <span class="iconfont_Me icon-qingchu iconStyle"></span>
            </div>
          </el-tooltip>
          <div class="buttonStyle rightButton" @click="confirmSelection">
            <span class="iconfont_Me icon-check iconStyle"></span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getRecommendCategories } from "@/api/newVersion/homePage";
import { getCategories } from "@/api/newVersion/pageHeader";
export default {
  data() {
    return {
      // 展开状态
      unfoldFlag: false,
      // 所有帖子类型
      categorys: [],
      // 选中的帖子类型
      selectedCategorys: [],
      // 系统推荐的默认类型
      recommendCategorys: []
    }
  },
  props: {
    isScrollUp: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // 默认展示类型列表
    defaultDisplayData() {
      return this.selectedCategorys.length === 0 ? this.recommendCategorys : this.selectedCategorys;
    }
  },
  watch: {
    // 监听主页列表的滚动状态
    isScrollUp: {
      handler(val, old) {
        if (val != old && !val) {
          this.clearSelection();
          this.unfoldFlag = false;
          this.$forceUpdate();
        }
      },
      deep: true
    },
    // 监听选中框打开的变化
    unfoldFlag: {
      handler(val, old) {
        if (val && val != old) {
          let searchCategoriesArr = this.$store.state.homePage.searchCategories.split(',').filter(e => e && e != '');
          this.categorys = this.categorys.map(e => {
            if (searchCategoriesArr.includes(e.id)) {
              e.isSelected = true;
            } else {
              e.isSelected = false;
            }
            return e;
          })
        }
      },
      deep: true
    },
    // 监听选中的类型集合变化
    selectedCategorys: {
      handler(val, old) {
        if (val !== old) {
          let searchCategories = '';
          for (let i = 0; i < val.length; i++) {
            // 判断是否被选中状态，并且不是全部按钮
            if (val[i].isSelected) {
              searchCategories += val[i].id + ','
            }
          }
          this.$store.commit('setSearchCategories', '');
          this.$nextTick(() => {
            searchCategories = searchCategories.slice(0, searchCategories.length - 1);
            this.$store.commit('setSearchCategories', searchCategories);
          })
        }
      },
      deep: true
    }
  },
  async mounted() {
    // 获取本地菜单
    await this.handlingGetCategories();
    let strongNav = window.localStorage.getItem("catchNavs");
    this.categorys = JSON.parse(strongNav);
    this.categorys.shift();
    this.categorys = this.categorys.map(e => {
      e.isSelected = false;
      return e;
    })
    await this.getRecommendCategories();
  },
  methods: {
    // 从后台获取类型列表，这里不能从本地记录里面拿，因为很header组件里的异步请求有可能还没有请求到数据，会拿到已经储存在本地的类型数据
    handlingGetCategories() {
      return new Promise(async (resolve, reject) => {
        let result = await getCategories();
        if (result.code == 200) {
          let navs = result.data.data;
          navs.unshift({
            category_id: 0,
            category_name: this.$t("all")
          });
          let catchNavs = [];
          navs.map(element => {
            let item = {
              id: element.category_id,
              name: element.category_name
            };
            catchNavs.push(item);
          });
          window.localStorage.setItem("catchNavs", JSON.stringify(catchNavs));
        } else {
          this.$message({
            type: "error",
            message: result.message
          })
        }
        resolve('success')
      })
    },
    // 获取推荐类型列表
    getRecommendCategories() {
      return new Promise(async (resolve, reject) => {
        let result = await getRecommendCategories();
        if (result.code == 200) {
          let catchNavs = [];
          result.data.data.map(element => {
            let item = {
              id: element.categoryId,
              name: element.categoryName
            };
            catchNavs.push(item);
          });
          this.recommendCategorys = catchNavs;
        } else {
          this.$message({
            type: "error",
            message: result.message
          })
        }
        resolve('success')
      })
    },
    // 清除已被选中的类型数据
    clearSelected() {
      this.selectedCategorys = [];
      this.$forceUpdate();
    },
    // 清除选中的类型
    clearSelection() {
      this.categorys = this.categorys.map(e => {
        e.isSelected = false;
        return e;
      })
    },
    // 确认选中的类型
    confirmSelection() {
      this.selectedCategorys = this.categorys.filter(e => e.isSelected);
      this.unfoldFlag = false;
      this.$forceUpdate();
    },
    // 选择这个类型
    selectedType(index) {
      this.categorys[index].isSelected = !this.categorys[index].isSelected;
      this.$forceUpdate();
    }
  }
}
</script>
<style lang="stylus" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s, transform .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform translate3d(0,-50px,0);
  }
  .typeSelectionContainer
    width 100%;
    position relative;
    z-index 99;
    .defaultDisplayArea
      width 100%;
      height 50px;
      cursor pointer;
      overflow hidden;
      display flex;
      flex-flow row;
      align-items center;
      justify-content flex-start;
      background #FFFFFF;
      .displayBox
        flex 1;
        height 50px;
        min-width 0;
        height 100%;
        padding 0 20px 0 40px;
        line-height 50px;
        text-align left;
        overflow hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        .typeItemBox
          padding 0 15px;
          border-radius 6px;
          display inline-block;
          height 30px;
          line-height 30px;
          border 1px solid #666666;
          font-size 14px;
          color #333333;
          margin-right 10px;
          user-select none;
          transition color .3s,background .3s,border .3s;
        .selectedStyle
          border 1px solid #33CC66 !important;
          background #F2FCF8 !important;
          color #33CC66;
      .isolationLine
        height 14px;
        width 1px;
        flex-shrink 0;
        background #CCCCCC;
      .operationButton
        width 24px;
        height 24px;
        flex-shrink 0;
        margin 0 40px 0 10px;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          font-size 20px;
          color #333333;
      .clearButton
        width 30px;
        height 30px;
        flex-shrink 0;
        margin 0 40px 0 10px;
        display flex;
        align-items center;
        justify-content center;
        background #F0F0F0;
        .iconStyle
          font-size 24px;
          color #666666;
    .unfoldDisplayArea
      top 0;
      left 0;
      z-index 2;
      width 100%;
      min-height 0;
      padding 20px 40px;
      background #FFFFFF;
      overflow hidden;
      position absolute;
      .displayBox
        min-height 0;
        width 100%;
        height 100%;
        text-align left;
        overflow hidden;
        white-space: wrap;
        line-height 36px;
        .typeItemBox
          padding 0 10px;
          border-radius 6px;
          display inline-block;
          height 30px;
          line-height 30px;
          border 1px solid #666666;
          font-size 14px;
          color #333333;
          margin-right 10px;
          user-select none;
          cursor pointer;
          transition color .3s,background .3s,border .3s;
        .selectedStyle
          border 1px solid #33CC66 !important;
          background #F2FCF8 !important;
          color #33CC66;
      .footerBox
        height 40px;
        width 100%;
        margin-top 20px;
        background transparent;
        display flex;
        align-items center;
        justify-content flex-start;
        .buttonStyle
          height 40px;
          cursor pointer;
          border-radius 4px;
          display flex;
          align-items center;
          justify-content center;
          .iconStyle
            font-size 22px;
        .leftButton
          width 60px;
          background #F0F0F0;
          margin-right 10px;
          .iconStyle
            color #666666
        .rightButton
          width 160px;
          background #33CC66;
          .iconStyle
            color #FFFFFF


</style>
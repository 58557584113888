var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebarContainer" },
    [
      _c("div", { staticClass: "goToPublishButtonBox" }, [
        _c(
          "div",
          { staticClass: "publishButton", on: { click: _vm.goPublishPage } },
          [
            _c(
              "el-tooltip",
              { attrs: { content: _vm.$t("post"), placement: "bottom" } },
              [
                _c("div", { staticClass: "publishIcon" }, [
                  _c("span", {
                    staticClass: "iconfont_Me icon-edit1 iconStyle"
                  })
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm.isLoggingIn
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.profileLoading,
                  expression: "profileLoading"
                }
              ],
              staticClass: "profileArea",
              staticStyle: { "margin-bottom": "31.5px" }
            },
            [
              _c(
                "div",
                { staticClass: "profileHeader" },
                [
                  _c(
                    "SharePopover",
                    {
                      attrs: { shareConfig: _vm.shareConfig },
                      on: { shareForMelinked: _vm.shareForMelinked }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("share"),
                            placement: "bottom"
                          }
                        },
                        [
                          _c("div", { staticClass: "shareButtonStyle" }, [
                            _c("span", {
                              staticClass: "iconfont_Me icon-share2 iconStyle"
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "avatarStyle loginAvatarStyle" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: "/",
                            "http-request": _vm.handlerUploadFile,
                            "show-file-list": false
                          }
                        },
                        [
                          _c("Avatar", {
                            attrs: {
                              path: _vm.userInfo.profilePhoto,
                              international:
                                _vm.userInfo.country !== ""
                                  ? Number(_vm.userInfo.country)
                                  : 0,
                              providerId: _vm.$store.getters.userInfo.id
                            }
                          }),
                          _c("div", { staticClass: "editAvatarMark" }, [
                            _c("span", {
                              staticClass: "iconfont_Me icon-shot iconStyle"
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "profileName profileCommon",
                  class: _vm.userInfo.vipStatus ? "cvipName" : "",
                  attrs: { title: _vm.userInfo.name }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.userInfo.name && _vm.userInfo.name.length > 10
                          ? _vm.userInfo.name.slice(0, 10) + ".."
                          : _vm.userInfo.name
                      ) +
                      "\n      "
                  ),
                  _vm.userInfo.vipStatus
                    ? _c(
                        "div",
                        { staticClass: "vipIcon" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("paidMember"),
                                placement: "bottom",
                                "popper-class": "me-tooltip-effect"
                              }
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "20px", height: "17px" },
                                attrs: {
                                  src: require("@/assets/images/pay/masonry.png"),
                                  fit: "fill"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleClickVip(_vm.userInfo.id)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c("div", { staticClass: "profileId profileCommon" }, [
                _vm._v("ID:" + _vm._s(_vm.userInfo.ucId))
              ]),
              _c(
                "div",
                { staticClass: "statisticalDataBox" },
                _vm._l(_vm.statisticalData, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.className,
                      staticClass: "statisticalDataItem",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return (() => {
                            if (index !== 2) {
                              _vm.jumpToDetails(item.tag)
                            }
                          }).apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: item.tooltipContent,
                            placement: "bottom"
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me iconStyle",
                            class: item.className
                          })
                        ]
                      ),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.handleNewVersionCount(item.count)) +
                          "\n      "
                      )
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.userIntro != ""
                ? [
                    _c("div", { staticClass: "dashedLine" }),
                    _vm.userIntro !== ""
                      ? _c("div", { staticClass: "introductionBox" }, [
                          _c(
                            "div",
                            { staticClass: "basicInformationIcon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: require("../../../assets/images/newHome/aboutMe.png"),
                                  fit: "cover"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "introductionContent" }, [
                            _c(
                              "div",
                              {
                                staticClass: "contentText maximumNumberOfRows2",
                                attrs: { title: _vm.userIntro }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.userIntro) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm.tags.length !== 0
                              ? _c(
                                  "div",
                                  { staticClass: "contentTags" },
                                  _vm._l(_vm.tags, function(item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item,
                                        staticClass: "contentTagItem"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              !_vm.profileLoading
                ? _c(
                    "div",
                    {
                      staticClass: "jumpButton",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.jumpToDetails(0)
                        }
                      }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t("personalCenter"),
                            placement: "bottom"
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "iconfont_Me icon-next iconStyle"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _c("div", { staticClass: "profileArea" }, [
            _c("div", { staticClass: "profileHeader" }, [
              _c(
                "div",
                {
                  staticClass: "avatarStyle",
                  on: { click: _vm.showLoginDialog }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "80px", height: "80px" },
                    attrs: {
                      src: require("../../../assets/images/newHome/notLoggedIn.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              )
            ])
          ]),
      _c("MelinkedTips")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }